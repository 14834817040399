import { configureStore } from "@reduxjs/toolkit"
import mapdataReducer from "./slices/mapdata/mapdataSlice"
import uiReducer from "./slices/ui/uiSlice"
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"

export function createStore() {
  return configureStore({
    reducer: {
      mapdata: mapdataReducer,
      ui: uiReducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  })
}

const store = createStore()
export const persistor = persistStore(store)

export default store
