export const trimZeroAfterPoint = value => {
  return parseFloat(Number(value).toFixed(3))
}

export const calculateDeriskingCost = fullSizedVerticalWell => {
  const preDrill = 3816200
  const otherCosts = 3000000
  const contingency = 1.25

  return contingency * (fullSizedVerticalWell + otherCosts) + preDrill
}

export const calculateRameyTimeFunction = (
  wellDiameter,
  rockThermalDiffusivity,
  year,
  capacityFactorPower
) => {
  return Number(
    (
      -Math.log(
        (1.1 * (wellDiameter / 2)) /
          Math.sqrt(
            4 * (rockThermalDiffusivity * year * capacityFactorPower * 31536000)
          )
      ) - 0.29
    ).toFixed(1)
  )
}

export const calculateWellTempLosses = (
  correctedGeothermalGradient,
  depth,
  gammaRamey
) => {
  return Number(
    (-(
      -correctedGeothermalGradient * (depth - gammaRamey) +
      -correctedGeothermalGradient * gammaRamey * Math.exp(-depth / gammaRamey)
    )).toFixed(3)
  )
}

export const calculateGAMMARamey = (
  massFlowRate,
  waterHeatCapacity,
  rameyTimeFunction,
  rockThermalConductivity
) => {
  return Number(
    (
      (massFlowRate * waterHeatCapacity * rameyTimeFunction) /
      2 /
      3.14 /
      rockThermalConductivity
    ).toFixed(3)
  )
}

export const getControlInputs = (controlValues, application) => {
  let productionTemperature
  let productionWellCount
  let massFlowRate
  let sedimentaryDrillingCostAdjuster
  // let temperatureDrillingCostMultiplier;
  let basementDrillingCostMultiplier
  let capexExpenditureSubsidy
  let annualFixedOPEX
  let wellsPlantConstructionTime
  let discountRate
  let temperatureGradientUncertainty
  let annualReservoirTemperatureDecline
  let deriskingYears
  let horizontalDrainLengh
  let injectionTemperature
  // let productionLifeTime;
  switch (application) {
    case "power":
      productionTemperature = controlValues.power_prod_temp
      productionWellCount = controlValues.power_num_wells
      massFlowRate = controlValues.power_flow_rate
      sedimentaryDrillingCostAdjuster =
        controlValues.power_sedimentary_drilling_cost_adjuster / 100
      // temperatureDrillingCostMultiplier = controlValues.power_temperature_drilling_cost_multiplier;
      basementDrillingCostMultiplier =
        controlValues.power_basement_drill_cost_mltpl_of_sed_avg
      capexExpenditureSubsidy = controlValues.power_capex_sub / 100
      annualFixedOPEX = controlValues.power_o_and_m / 100
      wellsPlantConstructionTime = controlValues.power_plant_cons
      discountRate = controlValues.power_disc_rate / 100
      temperatureGradientUncertainty =
        controlValues.power_temp_gradient_uncertainty
      deriskingYears = controlValues.power_derisking_years
      annualReservoirTemperatureDecline = controlValues.power_temp_decline
      horizontalDrainLengh = controlValues.power_horizontal_length
      // productionLifeTime = controlValues.power_production_lifetime
      injectionTemperature = 75
      break
    case "heat":
      productionTemperature = controlValues.heat_prod_temp
      productionWellCount = controlValues.heat_num_wells
      massFlowRate = controlValues.heat_flow_rate
      sedimentaryDrillingCostAdjuster =
        controlValues.heat_sedimentary_drilling_cost_adjuster / 100
      // temperatureDrillingCostMultiplier = controlValues.heat_temperature_drilling_cost_multiplier;
      basementDrillingCostMultiplier =
        controlValues.heat_basement_drill_cost_mltpl_of_sed_avg
      capexExpenditureSubsidy = controlValues.heat_capex_sub / 100
      annualFixedOPEX = controlValues.heat_o_and_m / 100
      wellsPlantConstructionTime = controlValues.heat_plant_cons
      discountRate = controlValues.heat_disc_rate / 100
      temperatureGradientUncertainty =
        controlValues.heat_temp_gradient_uncertainty
      annualReservoirTemperatureDecline = controlValues.heat_temp_decline
      deriskingYears = controlValues.heat_derisking_years
      horizontalDrainLengh = controlValues.heat_horizontal_length
      // productionLifeTime = controlValues.heat_production_lifetime;
      injectionTemperature = controlValues.heat_injection_temp
      break
    default:
      break
  }

  return {
    productionTemperature,
    productionWellCount,
    massFlowRate,
    sedimentaryDrillingCostAdjuster,
    // temperatureDrillingCostMultiplier,
    basementDrillingCostMultiplier,
    capexExpenditureSubsidy,
    annualFixedOPEX,
    wellsPlantConstructionTime,
    discountRate,
    temperatureGradientUncertainty,
    annualReservoirTemperatureDecline,
    deriskingYears,
    horizontalDrainLengh,
    injectionTemperature,
    // productionLifeTime
  }
}

export const calculateWaterHeatCapacity = bottonHoleTemperature => {
  const waterHeatCapacity6 = 1.38098e-16
  const waterHeatCapacity5 = -2.18187e-13
  const waterHeatCapacity4 = 0.000000000142033
  const waterHeatCapacity3 = -0.0000000465228
  const waterHeatCapacity2 = 0.00000865998
  const waterHeatCapacity1 = -0.000806161
  const waterHeatCapacity0 = 1.02617

  bottonHoleTemperature = bottonHoleTemperature * 1.8 + 32

  const waterHeatCapacity =
    waterHeatCapacity6 * bottonHoleTemperature ** 6 +
    waterHeatCapacity5 * bottonHoleTemperature ** 5 +
    waterHeatCapacity4 * bottonHoleTemperature ** 4 +
    waterHeatCapacity3 * bottonHoleTemperature ** 3 +
    waterHeatCapacity2 * bottonHoleTemperature ** 2 +
    waterHeatCapacity1 * bottonHoleTemperature +
    waterHeatCapacity0

  return Math.round(4184 * waterHeatCapacity)
}

export const calculateVerticalHorizontalDepth = (
  controlValues,
  geoValues,
  application
) => {
  const depthToBasement = geoValues.sed

  const {
    temperatureGradientUncertainty,
    productionTemperature,
    massFlowRate,
    horizontalDrainLengh,
    sedimentaryDrillingCostAdjuster,
    basementDrillingCostMultiplier,
  } = getControlInputs(controlValues, application)

  const correctedGeothermalGradient = temperatureGradientUncertainty / 1000
  const surfaceTemperature = Math.round(geoValues.surf)
  const surfaceTemperatureLosses = 4
  const wellheadTemperature = productionTemperature + surfaceTemperatureLosses
  const estimatedDeltaT = 10

  const bht1 = wellheadTemperature + estimatedDeltaT

  const depth1 = Math.round(
    (bht1 - surfaceTemperature) / correctedGeothermalGradient
  )

  const waterHeatCapacity1 = calculateWaterHeatCapacity(bht1)

  let wellDiameter = Number((6.4 * 0.0254).toFixed(3))

  if (massFlowRate > 60) {
    wellDiameter = Number((8.8 * 0.0254).toFixed(3))
  }

  const rockThermalConductivity = 1
  const rockHeatCapacity = 774
  const rockDensity = 2594
  const rockThermalDiffusivity =
    rockThermalConductivity / (rockHeatCapacity * rockDensity)
  const year = 1
  const capacityFactorPower = 0.9
  const RefTemperatureDrillCostCurve = 200

  const rameyTimeFunction = calculateRameyTimeFunction(
    wellDiameter,
    rockThermalDiffusivity,
    year,
    capacityFactorPower
  )

  const gammaRamey1 = calculateGAMMARamey(
    massFlowRate,
    waterHeatCapacity1,
    rameyTimeFunction,
    rockThermalConductivity
  )

  const wellTempLosses1 = calculateWellTempLosses(
    correctedGeothermalGradient,
    depth1,
    gammaRamey1
  )

  const bht2 = wellTempLosses1 + wellheadTemperature

  const depth2 = (bht2 - surfaceTemperature) / correctedGeothermalGradient

  const waterHeatCapacity2 = calculateWaterHeatCapacity(bht2)

  const gammaRamey2 = calculateGAMMARamey(
    massFlowRate,
    waterHeatCapacity2,
    rameyTimeFunction,
    rockThermalConductivity
  )

  const wellTempLosses2 = calculateWellTempLosses(
    correctedGeothermalGradient,
    depth2,
    gammaRamey2
  )

  const bhtFinal = Number((wellTempLosses2 + wellheadTemperature).toFixed(1))
  const depthFinal = Math.round(
    (bhtFinal - surfaceTemperature) / correctedGeothermalGradient
  )
  const depthDegreeC = Number(
    (RefTemperatureDrillCostCurve - surfaceTemperature) /
      correctedGeothermalGradient
  ).toFixed(2)

  const sedimentaryVerticalDepth = Math.min(depthFinal, depthToBasement * 1000)
  const basementVerticalDepth = Math.max(depthFinal - depthToBasement * 1000, 0)
  const basementHorizontalDepth =
    basementVerticalDepth > 0 ? horizontalDrainLengh : 0
  const sedimentaryHorizontalDepth =
    basementVerticalDepth === 0 ? horizontalDrainLengh : 0
  const sedimentaryDrillingCostReference = 1230.315

  const sedimentaryCostRef =
    (sedimentaryVerticalDepth + 0.01 + sedimentaryHorizontalDepth) *
    sedimentaryDrillingCostReference
  // const sedimentaryCostTCorrection = Number(
  // 	sedimentaryVerticalDepth < depthDegreeC
  // 	  ? sedimentaryCostRef
  // 	  : depthDegreeC * sedimentaryDrillingCostReference +
  // 		  (sedimentaryVerticalDepth - depthDegreeC) *
  // 			sedimentaryDrillingCostReference *
  // 			temperatureDrillingCostMultiplier +
  // 		  temperatureDrillingCostMultiplier * sedimentaryHorizontalDepth
  //   ).toFixed(2);

  const sedimentaryCost = sedimentaryCostRef * sedimentaryDrillingCostAdjuster
  const sedimentaryCostDollar =
    sedimentaryVerticalDepth === 0
      ? 0
      : sedimentaryCost /
        (sedimentaryVerticalDepth + sedimentaryHorizontalDepth)

  const basementCostRef =
    (basementHorizontalDepth + basementVerticalDepth) *
    sedimentaryDrillingCostReference *
    basementDrillingCostMultiplier
  // const basementCostTCorrection =
  // basementVerticalDepth + sedimentaryVerticalDepth < depthDegreeC
  //   ? basementCostRef
  //   : (depthDegreeC - sedimentaryVerticalDepth) *
  //       basementDrillingCostMultiplier *
  //       sedimentaryDrillingCostReference +
  //     (sedimentaryVerticalDepth + basementVerticalDepth - depthDegreeC) *
  //       basementDrillingCostMultiplier *
  //       temperatureDrillingCostMultiplier *
  //       sedimentaryDrillingCostReference +
  //     sedimentaryDrillingCostReference *
  //       basementDrillingCostMultiplier *
  //       temperatureDrillingCostMultiplier *
  //       basementHorizontalDepth

  const basementCost = basementCostRef * sedimentaryDrillingCostAdjuster
  const basementCostDollar =
    basementVerticalDepth === 0
      ? 0
      : basementCost / (basementVerticalDepth + basementHorizontalDepth)

  const fullSizedVerticalWell =
    sedimentaryVerticalDepth * sedimentaryCostDollar +
    basementVerticalDepth * basementCostDollar

  return {
    wellDiameter,
    massFlowRate,
    surfaceTemperature,
    correctedGeothermalGradient,
    gammaRamey1,
    bhtFinal,
    depthFinal,
    sedimentaryVerticalDepth,
    basementVerticalDepth,
    basementHorizontalDepth,
    sedimentaryHorizontalDepth,
    sedimentaryCostRef,
    sedimentaryCost,
    sedimentaryCostDollar,
    basementCostRef,
    basementCost,
    basementCostDollar,
    fullSizedVerticalWell,
  }
}

export const calculatePhydrostatic = (
  rhoWaterSurfaceKiloPerCubicMeter,
  bht,
  depth,
  correctedGeothermalGradient
) => {
  const currentTransformerOneBar = 0.000000000464
  const tCorrection = 30.796 * bht ** -0.552
  const currentTransformerOneCore = 0.0009 / tCorrection

  return (
    0 +
    (1 / currentTransformerOneBar) *
      (Math.exp(
        ((rhoWaterSurfaceKiloPerCubicMeter * 9.81 * currentTransformerOneBar) /
          1000) *
          (depth -
            (currentTransformerOneCore / 2) *
              correctedGeothermalGradient *
              depth *
              2)
      ) -
        1)
  )
}

export const calculateRhoWaterSurfaceKiloPerCubicMeter = temperatureF => {
  const specificVolumeCubicFeetPerPound6 = 1.4068205291e-18
  const specificVolumeCubicFeetPerPound5 = -2.6995711458e-15
  const specificVolumeCubicFeetPerPound4 = 2.1775771856e-12
  const specificVolumeCubicFeetPerPound3 = -0.00000000091528222658
  const specificVolumeCubicFeetPerPound2 = 0.00000022418007508
  const specificVolumeCubicFeetPerPound1 = -0.000023968043944
  const specificVolumeCubicFeetPerPound0 = 0.017070951786

  const rhoWaterSurface =
    specificVolumeCubicFeetPerPound6 * temperatureF ** 6 +
    specificVolumeCubicFeetPerPound5 * temperatureF ** 5 +
    specificVolumeCubicFeetPerPound4 * temperatureF ** 4 +
    specificVolumeCubicFeetPerPound3 * temperatureF ** 3 +
    specificVolumeCubicFeetPerPound2 * temperatureF ** 2 +
    specificVolumeCubicFeetPerPound1 * temperatureF +
    specificVolumeCubicFeetPerPound0

  const rhoWaterSurfaceCubicFeetPerPound = 1 / rhoWaterSurface

  const rhoWaterSurfaceKiloPerCubicMeter =
    rhoWaterSurfaceCubicFeetPerPound * 16.01846

  return Number(rhoWaterSurfaceKiloPerCubicMeter.toFixed(1))
}

export const calculatePressureSat = bhtF => {
  const pressureParam5 = -2.5517506351e-12
  const pressureParam4 = 0.000000024121846658
  const pressureParam3 = -0.0000091909636468
  const pressureParam2 = 0.0019695373372
  const pressureParam1 = -0.19788525656
  const pressureParam0 = 8.0894106754

  const pressureSat =
    pressureParam5 * bhtF ** 5 +
    pressureParam4 * bhtF ** 4 +
    pressureParam3 * bhtF ** 3 +
    pressureParam2 * bhtF ** 2 +
    pressureParam1 * bhtF +
    pressureParam0

  return Number(pressureSat.toFixed(2))
}

export const calculatePowerInjectorCost = (
  controlValues,
  geoValues,
  application
) => {
  const { productionWellCount, injectionTemperature } = getControlInputs(
    controlValues,
    application
  )

  const waterLoss = 0.01
  const injectorMeterPerSquare = 9.807
  const injectionWellCount = productionWellCount
  const injectivityIndex = 5
  const injectivityIndexKiloPascals = injectivityIndex / 100
  const viscosityA = 407.22
  const viscosityB = -1.194

  const {
    bhtFinal,
    depthFinal,
    correctedGeothermalGradient,
    surfaceTemperature,
    massFlowRate,
    wellDiameter,
    gammaRamey1,
  } = calculateVerticalHorizontalDepth(controlValues, geoValues, application)

  const surfaceTemperatureF = (surfaceTemperature * 9) / 5 + 32
  const powerRhoWaterSurfaceKiloPerCubicMeter =
    calculateRhoWaterSurfaceKiloPerCubicMeter(surfaceTemperatureF)

  const injectionTemperatureF = (injectionTemperature * 9) / 5 + 32
  const heatRhoWaterSurfaceKiloPerCubicMeter =
    calculateRhoWaterSurfaceKiloPerCubicMeter(injectionTemperatureF)

  const phydrostatic = calculatePhydrostatic(
    powerRhoWaterSurfaceKiloPerCubicMeter,
    bhtFinal,
    depthFinal,
    correctedGeothermalGradient
  )

  const roughnessInMeter = 0.0001 / wellDiameter

  const muWaterInjLb = (viscosityA * injectionTemperatureF ** viscosityB) / 3600
  const muWaterInjKg = muWaterInjLb * 1.48

  const injectorRE =
    (4 * massFlowRate) / (muWaterInjKg * Math.PI * wellDiameter)
  const injectorA =
    -2 * Math.log10(roughnessInMeter / wellDiameter / 3.7 + 12 / injectorRE)
  const injectorV =
    -2 *
    Math.log10(
      roughnessInMeter / wellDiameter / 3.7 + (2.51 * injectorA) / injectorRE
    )
  const injectorC =
    -2 *
    Math.log10(
      roughnessInMeter / wellDiameter / 3.7 + (2.51 * injectorV) / injectorRE
    )
  const injectorF1 =
    (injectorA -
      (injectorV - injectorA) ** 2 / (injectorC - 2 * injectorV + injectorA)) **
    -2

  const vProd =
    massFlowRate /
    heatRhoWaterSurfaceKiloPerCubicMeter /
    ((Math.PI / 4) * wellDiameter ** 2)

  const pinjWellHead =
    phydrostatic +
    (massFlowRate * (1 + waterLoss) * productionWellCount) /
      injectionWellCount /
      injectivityIndexKiloPascals -
    (heatRhoWaterSurfaceKiloPerCubicMeter *
      injectorMeterPerSquare *
      depthFinal) /
      1000 +
    (injectorF1 *
      ((heatRhoWaterSurfaceKiloPerCubicMeter * vProd ** 2) / 2) *
      (depthFinal / wellDiameter)) /
      1000

  const pressureExcess = 50
  const dpSurfaceplant = 68.95
  const pumpEfficiency = 0.8

  const wellTempLosses = calculateWellTempLosses(
    correctedGeothermalGradient,
    depthFinal,
    gammaRamey1
  )

  const bhtAverageC = bhtFinal - wellTempLosses / 4
  const bhtAverageF = (bhtAverageC * 9) / 5 + 32

  const pressureSat = calculatePressureSat(bhtAverageF)

  const pressureMinimumPsia = pressureExcess + pressureSat
  const pressureMinimumKpa = (pressureMinimumPsia / 14.5) * 100
  const pressurePlantOutlet = Math.round(pressureMinimumKpa - dpSurfaceplant)
  const dp1 = pinjWellHead - pressurePlantOutlet

  let pumpingPowerInj =
    dp1 < 0
      ? 0
      : (dp1 * injectionWellCount * (1 + waterLoss) * massFlowRate) /
        heatRhoWaterSurfaceKiloPerCubicMeter /
        pumpEfficiency /
        1000
  pumpingPowerInj = Number(pumpingPowerInj.toFixed(3))

  const pumpingPowerProd = pumpingPowerInj * 1341
  const pumpCost = 1750 * pumpingPowerProd ** 0.7
  const injectorCost = Number((pumpCost / 1000000).toFixed(2))

  return {
    pumpingPowerInj,
    injectorCost,
    bhtFinal,
    depthFinal,
    phydrostatic,
  }
}

export const calculateCapitalExpenditureSubsidy = (
  controlValues,
  customValues,
  application
) => {
  const powerPlantCost = controlValues.power_pp_cost_dollars_per_kw
  const { maxGrossPlantOutput, capexSpend } = customValues
  const { wellsPlantConstructionTime, capexExpenditureSubsidy } =
    getControlInputs(controlValues, application)

  const powerPlantCostMm =
    (maxGrossPlantOutput * 1000 * powerPlantCost) / 1000000

  const surfaceFacilityCost = powerPlantCostMm / wellsPlantConstructionTime
  const capitalExpenditureSubsidy =
    (surfaceFacilityCost + capexSpend) * capexExpenditureSubsidy

  return {
    surfaceFacilityCost,
    capitalExpenditureSubsidy,
  }
}

export const handlePlantWellDataBasedOnYear = (
  controlValues,
  geoValues,
  customValues,
  application
) => {
  const { bhtFinal } = calculateVerticalHorizontalDepth(
    controlValues,
    geoValues,
    application
  )

  const controlInput = getControlInputs(controlValues, application)
  const {
    deriskingYears,
    wellsPlantConstructionTime,
    annualReservoirTemperatureDecline,
  } = controlInput

  const productionLifeTime = 25
  const totalYearsBeforeProduction = wellsPlantConstructionTime + deriskingYears
  // const projectLifeTime = productionLifeTime + totalYearsBeforeProduction;
  const totalYears = productionLifeTime + totalYearsBeforeProduction

  const currentYear = new Date().getFullYear()
  const { capexSpend, surfaceFacilityCost } = customValues

  let totalSurfaceFacilityCost = 0
  let totalCapexSpend = 0

  const plantAndWellData = {}
  for (let y = 0; y < totalYears; y++) {
    const year = currentYear + y
    const data = {}
    const i = y + 1

    const bht = plantAndWellData[year - 1]?.bottomHoleTempInit || 0

    data["linearTemperatureDecline"] =
      i <= totalYearsBeforeProduction
        ? 0
        : i === totalYearsBeforeProduction + 1
        ? 0
        : annualReservoirTemperatureDecline
    data["bottomHoleTempInit"] =
      i <= totalYearsBeforeProduction
        ? 0
        : i === totalYearsBeforeProduction + 1
        ? bhtFinal
        : Number((bht - data["linearTemperatureDecline"]).toFixed(1))

    totalSurfaceFacilityCost +=
      i <= deriskingYears
        ? 0
        : i <= totalYearsBeforeProduction
        ? surfaceFacilityCost
        : 0
    totalCapexSpend +=
      i <= deriskingYears
        ? 0
        : i <= totalYearsBeforeProduction
        ? capexSpend / wellsPlantConstructionTime
        : 0

    plantAndWellData[year] = data
  }

  const totalCapex = totalSurfaceFacilityCost + totalCapexSpend

  return {
    totalSurfaceFacilityCost,
    totalCapexSpend,
    totalCapex,
  }
}
