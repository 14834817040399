import { createSlice } from "@reduxjs/toolkit"
import { get, set } from "lodash"

const initialState = {
  mapboxSources: {},
  mapboxLayers: {},
  infoLayers: {},
}

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setDisclaimerAgreement: (state, { payload, type }) => {
      return state
    },
  },
})

const isClient = typeof window !== "undefined"

let reducer = undefined

if (isClient) {
  const { persistReducer } = require("redux-persist")
  const storage = require("redux-persist/lib/storage").default

  const persistConfig = {
    key: "auth",
    storage,
  }
  reducer = persistReducer(persistConfig, uiSlice.reducer)
} else {
  reducer = uiSlice.reducer
}

export const {} = uiSlice.actions

export default reducer
